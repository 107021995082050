/*

Derived from https://backstage.io/storybook/?path=/story/plugins-home-templates--default-template
Docs: https://backstage.io/docs/getting-started/homepage

*/

import {
    HomePageToolkit,
    HomePageCompanyLogo,
} from '@backstage/plugin-home';
import { Content, Page, InfoCard, Link } from '@backstage/core-components';
import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import { ToolkitBuildkiteLogoIcon, ToolkitGrafanaLogoIcon, HugoLogoIcon } from './Toolkit';
import { LogoFull } from '../Root/Logo';

const useLogoStyles = makeStyles(theme => ({
    container: {
        margin: theme.spacing(5, 0),
    },
}));


export const Homepage = () => {
    const { container } = useLogoStyles();

    return (
        <Page themeId="home">
            <Content>
                <Grid container justifyContent="center" spacing={6}>
                    <HomePageCompanyLogo
                        className={container}
                        logo={<LogoFull />}
                    />
                    <Grid container item xs={12}>
                        <Grid item xs={12} md={6}>
                            <InfoCard title="Welcome">
                                {/* placeholder for content */}
                                <div>
                                    <h3>Welcome to gridX's Backstage instance!</h3>
                                    <p>On this portal, you'll find anything around the development department.<br /> What do we have planned:</p>
                                    <ul>
                                        <li>Adding Software Templates (Boilerplate projects)</li>
                                        <li>Add documentation and team handbooks</li>
                                        <li>Tell us your ideas on <Link to='https://grid-x.slack.com/archives/C056HMCGQ5U'>#21-team-sre</Link></li>
                                    </ul>
                                </div>
                            </InfoCard>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <HomePageToolkit
                                title='Important Tools'
                                tools={[{
                                    url: 'https://oculus.sre.gridx.de',
                                    label: 'Grafana',
                                    icon: <ToolkitGrafanaLogoIcon />,
                                },
                                {
                                    url: 'https://buildkite.com/gridX/',
                                    label: 'Buildkite',
                                    icon: <ToolkitBuildkiteLogoIcon />,
                                },
                                {
                                    url: 'https://engineering-blog.sre.gridx.de/',
                                    label: 'Engineering Blog',
                                    icon: <HugoLogoIcon />,
                                }
                                ]}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Content>
        </Page >
    );
};