import React from 'react';
import { ToolCard } from './ToolCard';
import {
  Content,
  ContentHeader,
  ItemCardGrid,
  SupportButton,
} from '@backstage/core-components';
import { ExploreTool } from '@backstage/plugin-explore-common';

// TODO: use config instead https://github.com/backstage/backstage/blob/master/plugins/explore-backend/CHANGELOG.md#008
const exploreTools: ExploreTool[] = [
  {
    title: 'Buildkite',
    description: 'Buildkite is a platform for running fast, secure, and scalable continuous integration pipelines on your own infrastructure.',
    url: 'https://buildkite.com/gridX',
    image: 'https://buildkite.com/_next/static/assets/assets/images/brand-assets/buildkite-logo-portrait-on-light-f7f1af58.png',
    tags: ['CI'],
  },
];

const Body = () => {
  return (
    <ItemCardGrid>
      {exploreTools.map((tool, index) => (
        <ToolCard key={index} card={tool} />
      ))}
    </ItemCardGrid>
  );
};

export const ToolExplorerContent = (props: { title?: string }) => (
  <Content noPadding>
    <ContentHeader title={props.title ?? 'Tools'}>
      <SupportButton>Discover the tools in your ecosystem.</SupportButton>
    </ContentHeader>
    <Body />
  </Content>
);