/*

Derived from https://backstage.io/storybook/?path=/story/plugins-home-templates--default-template
Docs: https://backstage.io/docs/getting-started/homepage

*/

import {
    HomePageCompanyLogo,
    HeaderWorldClock,
    ClockConfig,
} from '@backstage/plugin-home';
import { Content, Page, InfoCard, StructuredMetadataTable, Header } from '@backstage/core-components';
import { Chip, Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import { LogoFull } from '../Root/Logo';

const useLogoStyles = makeStyles(theme => ({
    container: {
        margin: theme.spacing(5, 0),
    },
}));


export const Teamspage = () => {
    const { container } = useLogoStyles();

    const timeFormat: Intl.DateTimeFormatOptions = {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
    };

    const clockConfigs: ClockConfig[] = [
        {
            label: 'NYC',
            timeZone: 'America/New_York',
        },
        {
            label: 'UTC',
            timeZone: 'UTC',
        },
        {
            label: 'BER',
            timeZone: 'Europe/Berlin',
        },
        {
            label: 'TYO',
            timeZone: 'Asia/Tokyo',
        },
    ];

    return (
        <Page themeId="home">
            <Content>
                <Grid container justifyContent="center" spacing={6}>
                    <HomePageCompanyLogo
                        className={container}
                        logo={<LogoFull />}
                    />
                    <Header title="Teams" pageTitleOverride="Home">
                        <HeaderWorldClock
                            clockConfigs={clockConfigs}
                            customTimeFormat={timeFormat}
                        />
                    </Header>
                    <Grid container item xs={12}>
                        <Grid item xs={6} md={4}>
                            <a href="catalog/default/group/solution-frontend-team">
                                <InfoCard title="Solution Frontend Team" subheader={
                                    <div>
                                        <Chip size="small" label="Solution" />
                                        <Chip size="small" label="Frontend" />
                                        <Chip size="small" label="React" />
                                    </div>
                                }>
                                    <div>
                                        <StructuredMetadataTable metadata={{
                                            description:
                                                'tbd.',
                                            Contact:
                                                <div>
                                                    <a style={{ display: "flex", lineHeight: "24px" }} href="https://slack.com/app_redirect?channel=21-team-solution">
                                                        <svg style={{ marginRight: 5 }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M6 15a2 2 0 0 1-2 2a2 2 0 0 1-2-2a2 2 0 0 1 2-2h2v2m1 0a2 2 0 0 1 2-2a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2a2 2 0 0 1-2-2v-5m2-8a2 2 0 0 1-2-2a2 2 0 0 1 2-2a2 2 0 0 1 2 2v2H9m0 1a2 2 0 0 1 2 2a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2a2 2 0 0 1 2-2h5m8 2a2 2 0 0 1 2-2a2 2 0 0 1 2 2a2 2 0 0 1-2 2h-2v-2m-1 0a2 2 0 0 1-2 2a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2a2 2 0 0 1 2 2v5m-2 8a2 2 0 0 1 2 2a2 2 0 0 1-2 2a2 2 0 0 1-2-2v-2h2m0-1a2 2 0 0 1-2-2a2 2 0 0 1 2-2h5a2 2 0 0 1 2 2a2 2 0 0 1-2 2h-5Z" /></svg>
                                                        #21-team-solution
                                                    </a>
                                                    <br />
                                                    <a style={{ display: "flex", lineHeight: "24px" }} href="https://github.com/orgs/grid-x/teams/solution-frontend-team">
                                                        <svg style={{ marginRight: 5 }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z" /></svg>
                                                        solution-frontend-team
                                                    </a>
                                                </div>,
                                        }} />
                                    </div>
                                </InfoCard>
                            </a>
                        </Grid>
                        <Grid item xs={6} md={4}>
                            <a href="catalog/default/group/solution-backend-team">
                                <InfoCard title="Solution Backend Team" subheader={
                                    <div>
                                        <Chip size="small" label="Solution" />
                                        <Chip size="small" label="Backend" />
                                        <Chip size="small" label="Go" />
                                    </div>
                                }>
                                    <div>
                                        <StructuredMetadataTable metadata={{
                                            description:
                                                'tbd.',
                                            Contact:
                                                <div>
                                                    <a style={{ display: "flex", lineHeight: "24px" }} href="https://slack.com/app_redirect?channel=tbd">
                                                        <svg style={{ marginRight: 5 }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M6 15a2 2 0 0 1-2 2a2 2 0 0 1-2-2a2 2 0 0 1 2-2h2v2m1 0a2 2 0 0 1 2-2a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2a2 2 0 0 1-2-2v-5m2-8a2 2 0 0 1-2-2a2 2 0 0 1 2-2a2 2 0 0 1 2 2v2H9m0 1a2 2 0 0 1 2 2a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2a2 2 0 0 1 2-2h5m8 2a2 2 0 0 1 2-2a2 2 0 0 1 2 2a2 2 0 0 1-2 2h-2v-2m-1 0a2 2 0 0 1-2 2a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2a2 2 0 0 1 2 2v5m-2 8a2 2 0 0 1 2 2a2 2 0 0 1-2 2a2 2 0 0 1-2-2v-2h2m0-1a2 2 0 0 1-2-2a2 2 0 0 1 2-2h5a2 2 0 0 1 2 2a2 2 0 0 1-2 2h-5Z" /></svg>
                                                        tbd.
                                                    </a>
                                                    <br />
                                                    <a style={{ display: "flex", lineHeight: "24px" }} href="https://github.com/orgs/grid-x/teams/solution-backend-team">
                                                        <svg style={{ marginRight: 5 }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z" /></svg>
                                                        solution-backend-team
                                                    </a>
                                                </div>,
                                        }} />
                                    </div>
                                </InfoCard>
                            </a>
                        </Grid>
                        <Grid item xs={6} md={4}>
                            <a href="catalog/default/group/edge-connector-team">
                                <InfoCard title="Edge Connector Team" subheader={
                                    <div>
                                        <Chip size="small" label="Platform" />
                                        <Chip size="small" label="Backend" />
                                        <Chip size="small" label="Go" />
                                    </div>
                                }>
                                    <div>
                                        <StructuredMetadataTable metadata={{
                                            description:
                                                'tbd.',
                                            Contact:
                                                <div>
                                                    <a style={{ display: "flex", lineHeight: "24px" }} href="https://slack.com/app_redirect?channel=21-team-edge-connector">
                                                        <svg style={{ marginRight: 5 }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M6 15a2 2 0 0 1-2 2a2 2 0 0 1-2-2a2 2 0 0 1 2-2h2v2m1 0a2 2 0 0 1 2-2a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2a2 2 0 0 1-2-2v-5m2-8a2 2 0 0 1-2-2a2 2 0 0 1 2-2a2 2 0 0 1 2 2v2H9m0 1a2 2 0 0 1 2 2a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2a2 2 0 0 1 2-2h5m8 2a2 2 0 0 1 2-2a2 2 0 0 1 2 2a2 2 0 0 1-2 2h-2v-2m-1 0a2 2 0 0 1-2 2a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2a2 2 0 0 1 2 2v5m-2 8a2 2 0 0 1 2 2a2 2 0 0 1-2 2a2 2 0 0 1-2-2v-2h2m0-1a2 2 0 0 1-2-2a2 2 0 0 1 2-2h5a2 2 0 0 1 2 2a2 2 0 0 1-2 2h-5Z" /></svg>
                                                        #21-team-edge-connector
                                                    </a>
                                                    <br />
                                                    <a style={{ display: "flex", lineHeight: "24px" }} href="https://github.com/orgs/grid-x/teams/edge-connector-team">
                                                        <svg style={{ marginRight: 5 }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z" /></svg>
                                                        edge-connector-team
                                                    </a>
                                                </div>,
                                        }} />
                                    </div>
                                </InfoCard>
                            </a>
                        </Grid>
                        <Grid item xs={6} md={4}>
                            <a href="catalog/default/group/data-lake-calculator-team">
                                <InfoCard title="Data Lake & Calculator Team" subheader={
                                    <div>
                                        <Chip size="small" label="Platform" />
                                        <Chip size="small" label="Backend" />
                                        <Chip size="small" label="Go" />
                                    </div>
                                }>
                                    <div>
                                        <StructuredMetadataTable metadata={{
                                            description:
                                                'tbd.',
                                            Contact:
                                                <div>
                                                    <a style={{ display: "flex", lineHeight: "24px" }} href="https://slack.com/app_redirect?channel=21-team-data-lake-calculator">
                                                        <svg style={{ marginRight: 5 }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M6 15a2 2 0 0 1-2 2a2 2 0 0 1-2-2a2 2 0 0 1 2-2h2v2m1 0a2 2 0 0 1 2-2a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2a2 2 0 0 1-2-2v-5m2-8a2 2 0 0 1-2-2a2 2 0 0 1 2-2a2 2 0 0 1 2 2v2H9m0 1a2 2 0 0 1 2 2a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2a2 2 0 0 1 2-2h5m8 2a2 2 0 0 1 2-2a2 2 0 0 1 2 2a2 2 0 0 1-2 2h-2v-2m-1 0a2 2 0 0 1-2 2a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2a2 2 0 0 1 2 2v5m-2 8a2 2 0 0 1 2 2a2 2 0 0 1-2 2a2 2 0 0 1-2-2v-2h2m0-1a2 2 0 0 1-2-2a2 2 0 0 1 2-2h5a2 2 0 0 1 2 2a2 2 0 0 1-2 2h-5Z" /></svg>
                                                        #21-team-data-lake-calculator
                                                    </a>
                                                    <br />
                                                    <a style={{ display: "flex", lineHeight: "24px" }} href="https://github.com/orgs/grid-x/teams/data-lake-calculator-team">
                                                        <svg style={{ marginRight: 5 }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z" /></svg>
                                                        data-lake-calculator-team
                                                    </a>
                                                </div>,
                                        }} />
                                    </div>
                                </InfoCard>
                            </a>
                        </Grid>
                        <Grid item xs={6} md={4}>
                            <a href="catalog/default/group/platform-operations-team">
                                <InfoCard title="Platform Operations Team" subheader={
                                    <div>
                                        <Chip size="small" label="Platform" />
                                        <Chip size="small" label="Backend" />
                                        <Chip size="small" label="Go" />
                                    </div>
                                }>
                                    <div>
                                        <StructuredMetadataTable metadata={{
                                            description:
                                                'This is now the Asset Management Team',
                                            Contact:
                                                <div>
                                                    <a style={{ display: "flex", lineHeight: "24px" }} href="https://slack.com/app_redirect?channel=21-team-platform-operations">
                                                        <svg style={{ marginRight: 5 }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M6 15a2 2 0 0 1-2 2a2 2 0 0 1-2-2a2 2 0 0 1 2-2h2v2m1 0a2 2 0 0 1 2-2a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2a2 2 0 0 1-2-2v-5m2-8a2 2 0 0 1-2-2a2 2 0 0 1 2-2a2 2 0 0 1 2 2v2H9m0 1a2 2 0 0 1 2 2a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2a2 2 0 0 1 2-2h5m8 2a2 2 0 0 1 2-2a2 2 0 0 1 2 2a2 2 0 0 1-2 2h-2v-2m-1 0a2 2 0 0 1-2 2a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2a2 2 0 0 1 2 2v5m-2 8a2 2 0 0 1 2 2a2 2 0 0 1-2 2a2 2 0 0 1-2-2v-2h2m0-1a2 2 0 0 1-2-2a2 2 0 0 1 2-2h5a2 2 0 0 1 2 2a2 2 0 0 1-2 2h-5Z" /></svg>
                                                        #21-team-platform-operations
                                                    </a>
                                                    <br />
                                                    <a style={{ display: "flex", lineHeight: "24px" }} href="https://github.com/orgs/grid-x/teams/data-lake-calculator-team">
                                                        <svg style={{ marginRight: 5 }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z" /></svg>
                                                        platform-operations-team
                                                    </a>
                                                </div>,
                                        }} />
                                    </div>
                                </InfoCard>
                            </a>
                        </Grid>
                        <Grid item xs={6} md={4}>
                            <a href="catalog/default/group/asset-management-team">
                                <InfoCard title="Asset Management Team" subheader={
                                    <div>
                                        <Chip size="small" label="Backend" />
                                        <Chip size="small" label="Go" />
                                    </div>
                                }>
                                    <div>
                                        <StructuredMetadataTable metadata={{
                                            description:
                                                'Provide APIs to retrieve and act on Assets, Gateways & Systems. Formerly known as Platform Operations Team.',
                                            Contact:
                                                <div>
                                                    <a style={{ display: "flex", lineHeight: "24px" }} href="https://slack.com/app_redirect?channel=21-team-asset-management">
                                                        <svg style={{ marginRight: 5 }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M6 15a2 2 0 0 1-2 2a2 2 0 0 1-2-2a2 2 0 0 1 2-2h2v2m1 0a2 2 0 0 1 2-2a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2a2 2 0 0 1-2-2v-5m2-8a2 2 0 0 1-2-2a2 2 0 0 1 2-2a2 2 0 0 1 2 2v2H9m0 1a2 2 0 0 1 2 2a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2a2 2 0 0 1 2-2h5m8 2a2 2 0 0 1 2-2a2 2 0 0 1 2 2a2 2 0 0 1-2 2h-2v-2m-1 0a2 2 0 0 1-2 2a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2a2 2 0 0 1 2 2v5m-2 8a2 2 0 0 1 2 2a2 2 0 0 1-2 2a2 2 0 0 1-2-2v-2h2m0-1a2 2 0 0 1-2-2a2 2 0 0 1 2-2h5a2 2 0 0 1 2 2a2 2 0 0 1-2 2h-5Z" /></svg>
                                                        #21-team-asset-management
                                                    </a>
                                                    <br />
                                                    <a style={{ display: "flex", lineHeight: "24px" }} href="https://github.com/orgs/grid-x/teams/asset-management-team">
                                                        <svg style={{ marginRight: 5 }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z" /></svg>
                                                        asset-management-team
                                                    </a>
                                                </div>,
                                        }} />
                                    </div>
                                </InfoCard>
                            </a>
                        </Grid>
                        <Grid item xs={6} md={4}>
                            <a href="catalog/default/group/integration-team">
                                <InfoCard title="Integration Team" subheader={
                                    <div>
                                        <Chip size="small" label="Platform" />
                                        <Chip size="small" label="gridBox" />
                                        <Chip size="small" label="Go" />
                                    </div>
                                }>
                                    <div>
                                        <StructuredMetadataTable metadata={{
                                            description:
                                                'tbd.',
                                            Contact:
                                                <div>
                                                    <a style={{ display: "flex", lineHeight: "24px" }} href="https://slack.com/app_redirect?channel=tbd">
                                                        <svg style={{ marginRight: 5 }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M6 15a2 2 0 0 1-2 2a2 2 0 0 1-2-2a2 2 0 0 1 2-2h2v2m1 0a2 2 0 0 1 2-2a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2a2 2 0 0 1-2-2v-5m2-8a2 2 0 0 1-2-2a2 2 0 0 1 2-2a2 2 0 0 1 2 2v2H9m0 1a2 2 0 0 1 2 2a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2a2 2 0 0 1 2-2h5m8 2a2 2 0 0 1 2-2a2 2 0 0 1 2 2a2 2 0 0 1-2 2h-2v-2m-1 0a2 2 0 0 1-2 2a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2a2 2 0 0 1 2 2v5m-2 8a2 2 0 0 1 2 2a2 2 0 0 1-2 2a2 2 0 0 1-2-2v-2h2m0-1a2 2 0 0 1-2-2a2 2 0 0 1 2-2h5a2 2 0 0 1 2 2a2 2 0 0 1-2 2h-5Z" /></svg>
                                                        tbd.
                                                    </a>
                                                    <br />
                                                    <a style={{ display: "flex", lineHeight: "24px" }} href="https://github.com/orgs/grid-x/teams/integration-team">
                                                        <svg style={{ marginRight: 5 }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z" /></svg>
                                                        integration-team
                                                    </a>
                                                </div>,
                                        }} />
                                    </div>
                                </InfoCard>
                            </a>
                        </Grid>
                        <Grid item xs={6} md={4}>
                            <a href="catalog/default/group/optifra-team">
                                <InfoCard title="OptiFra Team" subheader={
                                    <div>
                                        <Chip size="small" label="Platform" />
                                        <Chip size="small" label="EMS" />
                                        <Chip size="small" label="Python" />
                                    </div>
                                }>
                                    <div>
                                        <StructuredMetadataTable metadata={{
                                            description:
                                                'tbd.',
                                            Contact:
                                                <div>
                                                    <a style={{ display: "flex", lineHeight: "24px" }} href="https://slack.com/app_redirect?channel=21-team-optifra">
                                                        <svg style={{ marginRight: 5 }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M6 15a2 2 0 0 1-2 2a2 2 0 0 1-2-2a2 2 0 0 1 2-2h2v2m1 0a2 2 0 0 1 2-2a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2a2 2 0 0 1-2-2v-5m2-8a2 2 0 0 1-2-2a2 2 0 0 1 2-2a2 2 0 0 1 2 2v2H9m0 1a2 2 0 0 1 2 2a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2a2 2 0 0 1 2-2h5m8 2a2 2 0 0 1 2-2a2 2 0 0 1 2 2a2 2 0 0 1-2 2h-2v-2m-1 0a2 2 0 0 1-2 2a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2a2 2 0 0 1 2 2v5m-2 8a2 2 0 0 1 2 2a2 2 0 0 1-2 2a2 2 0 0 1-2-2v-2h2m0-1a2 2 0 0 1-2-2a2 2 0 0 1 2-2h5a2 2 0 0 1 2 2a2 2 0 0 1-2 2h-5Z" /></svg>
                                                        #21-team-optifra
                                                    </a>
                                                    <br />
                                                    <a style={{ display: "flex", lineHeight: "24px" }} href="https://github.com/orgs/grid-x/teams/optifra-team">
                                                        <svg style={{ marginRight: 5 }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z" /></svg>
                                                        optifra-team
                                                    </a>
                                                </div>
                                        }} />
                                    </div>
                                </InfoCard>
                            </a>
                        </Grid>
                        <Grid item xs={6} md={4}>
                            <a href="catalog/default/group/rule-based-ems-team">
                                <InfoCard title="Rule-based EMS Team" subheader={
                                    <div>
                                        <Chip size="small" label="Platform" />
                                        <Chip size="small" label="EMS" />
                                        <Chip size="small" label="Go" />
                                    </div>
                                }>
                                    <div>
                                        <StructuredMetadataTable metadata={{
                                            description:
                                                'tbd.',
                                            Contact:
                                                <div>
                                                    <a style={{ display: "flex", lineHeight: "24px" }} href="https://slack.com/app_redirect?channel=tbd">
                                                        <svg style={{ marginRight: 5 }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M6 15a2 2 0 0 1-2 2a2 2 0 0 1-2-2a2 2 0 0 1 2-2h2v2m1 0a2 2 0 0 1 2-2a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2a2 2 0 0 1-2-2v-5m2-8a2 2 0 0 1-2-2a2 2 0 0 1 2-2a2 2 0 0 1 2 2v2H9m0 1a2 2 0 0 1 2 2a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2a2 2 0 0 1 2-2h5m8 2a2 2 0 0 1 2-2a2 2 0 0 1 2 2a2 2 0 0 1-2 2h-2v-2m-1 0a2 2 0 0 1-2 2a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2a2 2 0 0 1 2 2v5m-2 8a2 2 0 0 1 2 2a2 2 0 0 1-2 2a2 2 0 0 1-2-2v-2h2m0-1a2 2 0 0 1-2-2a2 2 0 0 1 2-2h5a2 2 0 0 1 2 2a2 2 0 0 1-2 2h-5Z" /></svg>
                                                        tbd.
                                                    </a>
                                                    <br />
                                                    <a style={{ display: "flex", lineHeight: "24px" }} href="https://github.com/orgs/grid-x/teams/rule-based-ems-team">
                                                        <svg style={{ marginRight: 5 }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z" /></svg>
                                                        rule-based-ems-team
                                                    </a>
                                                </div>,
                                        }} />
                                    </div>
                                </InfoCard>
                            </a>
                        </Grid>
                        <Grid item xs={6} md={4}>
                            <a href="catalog/default/group/sre-team">
                                <InfoCard title="SRE Team" subheader={
                                    <div>
                                        <Chip size="small" label="Infrastructure" />
                                    </div>
                                }>
                                    <div>
                                        <StructuredMetadataTable metadata={{
                                            description:
                                                'tbd.',
                                            Contact:
                                                <div>
                                                    <a style={{ display: "flex", lineHeight: "24px" }} href="https://slack.com/app_redirect?channel=21-team-sre">
                                                        <svg style={{ marginRight: 5 }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M6 15a2 2 0 0 1-2 2a2 2 0 0 1-2-2a2 2 0 0 1 2-2h2v2m1 0a2 2 0 0 1 2-2a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2a2 2 0 0 1-2-2v-5m2-8a2 2 0 0 1-2-2a2 2 0 0 1 2-2a2 2 0 0 1 2 2v2H9m0 1a2 2 0 0 1 2 2a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2a2 2 0 0 1 2-2h5m8 2a2 2 0 0 1 2-2a2 2 0 0 1 2 2a2 2 0 0 1-2 2h-2v-2m-1 0a2 2 0 0 1-2 2a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2a2 2 0 0 1 2 2v5m-2 8a2 2 0 0 1 2 2a2 2 0 0 1-2 2a2 2 0 0 1-2-2v-2h2m0-1a2 2 0 0 1-2-2a2 2 0 0 1 2-2h5a2 2 0 0 1 2 2a2 2 0 0 1-2 2h-5Z" /></svg>
                                                        #21-team-sre
                                                    </a>
                                                    <br />
                                                    <a style={{ display: "flex", lineHeight: "24px" }} href="https://github.com/orgs/grid-x/teams/sre-cloud-infrastructure-team">
                                                        <svg style={{ marginRight: 5 }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z" /></svg>
                                                        sre-cloud-infrastructure-team
                                                    </a>
                                                    <br />
                                                    <a style={{ display: "flex", lineHeight: "24px" }} href="https://github.com/orgs/grid-x/teams/sre-device-infrastructure-team">
                                                        <svg style={{ marginRight: 5 }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z" /></svg>
                                                        sre-device-infrastructure-team
                                                    </a>
                                                </div>,
                                        }} />
                                    </div>
                                </InfoCard>
                            </a>
                        </Grid>
                        <Grid item xs={6} md={4}>
                            <a href="catalog/default/group/security-team">
                                <InfoCard title="Security Team" subheader={
                                    <div>
                                        <Chip size="small" label="Infrastructure" />
                                    </div>
                                }>
                                    <div>
                                        <StructuredMetadataTable metadata={{
                                            description:
                                                'tbd.',
                                            Contact:
                                                <div>
                                                    <a style={{ display: "flex", lineHeight: "24px" }} href="https://slack.com/app_redirect?channel=tbd">
                                                        <svg style={{ marginRight: 5 }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M6 15a2 2 0 0 1-2 2a2 2 0 0 1-2-2a2 2 0 0 1 2-2h2v2m1 0a2 2 0 0 1 2-2a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2a2 2 0 0 1-2-2v-5m2-8a2 2 0 0 1-2-2a2 2 0 0 1 2-2a2 2 0 0 1 2 2v2H9m0 1a2 2 0 0 1 2 2a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2a2 2 0 0 1 2-2h5m8 2a2 2 0 0 1 2-2a2 2 0 0 1 2 2a2 2 0 0 1-2 2h-2v-2m-1 0a2 2 0 0 1-2 2a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2a2 2 0 0 1 2 2v5m-2 8a2 2 0 0 1 2 2a2 2 0 0 1-2 2a2 2 0 0 1-2-2v-2h2m0-1a2 2 0 0 1-2-2a2 2 0 0 1 2-2h5a2 2 0 0 1 2 2a2 2 0 0 1-2 2h-5Z" /></svg>
                                                        tbd.
                                                    </a>
                                                    <br />
                                                    <a style={{ display: "flex", lineHeight: "24px" }} href="https://github.com/orgs/grid-x/teams/security-team">
                                                        <svg style={{ marginRight: 5 }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z" /></svg>
                                                        security-team
                                                    </a>
                                                </div>,
                                        }} />
                                    </div>
                                </InfoCard>
                            </a>
                        </Grid>
                        <Grid item xs={6} md={4}>
                            <a href="catalog/default/group/design-team">
                                <InfoCard title="Design Team" subheader={
                                    <div>
                                        <Chip size="small" label="Frontend" />
                                    </div>
                                }>
                                    <div>
                                        <StructuredMetadataTable metadata={{
                                            description:
                                                'tbd.',
                                            Contact:
                                                <div>
                                                    <a style={{ display: "flex", lineHeight: "24px" }} href="https://slack.com/app_redirect?channel=tbd">
                                                        <svg style={{ marginRight: 5 }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M6 15a2 2 0 0 1-2 2a2 2 0 0 1-2-2a2 2 0 0 1 2-2h2v2m1 0a2 2 0 0 1 2-2a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2a2 2 0 0 1-2-2v-5m2-8a2 2 0 0 1-2-2a2 2 0 0 1 2-2a2 2 0 0 1 2 2v2H9m0 1a2 2 0 0 1 2 2a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2a2 2 0 0 1 2-2h5m8 2a2 2 0 0 1 2-2a2 2 0 0 1 2 2a2 2 0 0 1-2 2h-2v-2m-1 0a2 2 0 0 1-2 2a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2a2 2 0 0 1 2 2v5m-2 8a2 2 0 0 1 2 2a2 2 0 0 1-2 2a2 2 0 0 1-2-2v-2h2m0-1a2 2 0 0 1-2-2a2 2 0 0 1 2-2h5a2 2 0 0 1 2 2a2 2 0 0 1-2 2h-5Z" /></svg>
                                                        tbd.
                                                    </a>
                                                    <br />
                                                    <a style={{ display: "flex", lineHeight: "24px" }} href="https://github.com/orgs/grid-x/teams/design-team">
                                                        <svg style={{ marginRight: 5 }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z" /></svg>
                                                        design-team
                                                    </a>
                                                </div>,
                                        }} />
                                    </div>
                                </InfoCard>
                            </a>
                        </Grid>
                    </Grid>
                </Grid>
            </Content>
        </Page >
    );
};
