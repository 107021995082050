import {
    GroupEntity,
    parseEntityRef,
    RELATION_CHILD_OF,
    stringifyEntityRef,
  } from '@backstage/catalog-model';
  import {
    DependencyGraph,
    DependencyGraphTypes,
    Link,
    Progress,
    ResponseErrorPanel,
  } from '@backstage/core-components';
  import { configApiRef, useApi, useRouteRef } from '@backstage/core-plugin-api';
  import {
    catalogApiRef,
    entityRouteRef,
    humanizeEntityRef,
    getEntityRelations,
  } from '@backstage/plugin-catalog-react';
  import { BackstageTheme } from '@backstage/theme';
  import { makeStyles, Typography, useTheme } from '@material-ui/core';
  import ZoomOutMap from '@material-ui/icons/ZoomOutMap';
  import classNames from 'classnames';
  import React from 'react';
  import useAsync from 'react-use/lib/useAsync';
  
  const useStyles = makeStyles((theme: BackstageTheme) => ({
    graph: {
      minHeight: '100%',
      flex: 1,
    },
    graphWrapper: {
      display: 'flex',
      height: '100%',
    },
    organizationNode: {
      fill: theme.palette.secondary.light,
      stroke: theme.palette.secondary.light,
    },
    groupNode: {
      fill: theme.palette.primary.light,
      stroke: theme.palette.primary.light,
    },
    centeredContent: {
      padding: theme.spacing(1),
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: theme.palette.common.black,
    },
    legend: {
      position: 'absolute',
      bottom: 0,
      right: 0,
      padding: theme.spacing(1),
      '& .icon': {
        verticalAlign: 'bottom',
      },
    },
    textOrganization: {
      color: theme.palette.secondary.contrastText,
    },
    textGroup: {
      color: theme.palette.primary.contrastText,
    },
    textWrapper: {
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: 2,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      textAlign: 'center',
      fontWeight: 'bold',
      fontSize: '20px',
    },
  }));
  
  function RenderNode(props: DependencyGraphTypes.RenderNodeProps<any>) {
    const nodeWidth = 180;
    const nodeHeight = 60;
    const theme = useTheme();
    const classes = useStyles();
    const catalogEntityRoute = useRouteRef(entityRouteRef);
  
    if (props.node.id === 'root') {
      return (
        <g>
          <rect
            width={nodeWidth}
            height={nodeHeight}
            rx={theme.shape.borderRadius}
            className={classes.organizationNode}
          />
          <title>{props.node.name}</title>
          <foreignObject width={nodeWidth} height={nodeHeight}>
            <div className={classes.centeredContent}>
              <div
                className={classNames(
                  classes.textWrapper,
                  classes.textOrganization,
                )}
              >
                {props.node.name}
              </div>
            </div>
          </foreignObject>
        </g>
      );
    }
  
    const ref = parseEntityRef(props.node.id);
  
    return (
      <g>
        <rect
          width={nodeWidth}
          height={nodeHeight}
          rx={theme.shape.borderRadius}
          className={classes.groupNode}
        />
        <title>{props.node.name}</title>
  
        <Link
          to={catalogEntityRoute({
            kind: ref.kind,
            namespace: ref.namespace,
            name: ref.name,
          })}
        >
          <foreignObject width={nodeWidth} height={nodeHeight}>
            <div className={classes.centeredContent}>
              <div className={classNames(classes.textWrapper, classes.textGroup)}>
                {props.node.name}
              </div>
            </div>
          </foreignObject>
        </Link>
      </g>
    );
  }
  
  /**
   * Dynamically generates a diagram of groups registered in the catalog.
   */
  export function GroupsDiagram() {
    const nodes = new Array<{
      id: string;
      kind: string;
      name: string;
    }>();
    const edges = new Array<{ from: string; to: string; label: string }>();
  
    const classes = useStyles();
    const configApi = useApi(configApiRef);
    const catalogApi = useApi(catalogApiRef);
    const organizationName =
      configApi.getOptionalString('organization.name') ?? 'Backstage';
    const {
      loading,
      error,
      value: catalogResponse,
    } = useAsync(() => {
      return catalogApi.getEntities({
        filter: {
          kind: ['Group'],
        },
      });
    }, [catalogApi]);
  
    if (loading) {
      return <Progress />;
    } else if (error) {
      return <ResponseErrorPanel error={error} />;
    }
  
    // the root of this diagram is the organization
    nodes.push({
      id: 'root',
      kind: 'Organization',
      name: organizationName,
    });
  
    for (const catalogItem of catalogResponse?.items || []) {
      const currentItemId = stringifyEntityRef(catalogItem);
  
      nodes.push({
        id: stringifyEntityRef(catalogItem),
        kind: catalogItem.kind,
        name:
          (catalogItem as GroupEntity).spec?.profile?.displayName ||
          humanizeEntityRef(catalogItem, { defaultKind: 'Group' }),
      });
  
      // Edge to parent
      const catalogItemRelations_childOf = getEntityRelations(
        catalogItem,
        RELATION_CHILD_OF,
      );
  
      // if no parent is found, link the node to the root
      if (catalogItemRelations_childOf.length === 0) {
        edges.push({
          from: currentItemId,
          to: 'root',
          label: '',
        });
      }
  
      catalogItemRelations_childOf.forEach(relation => {
        edges.push({
          from: currentItemId,
          to: stringifyEntityRef(relation),
          label: '',
        });
      });
    }
  
    return (
      <div className={classes.graphWrapper}>
        <DependencyGraph
          nodes={nodes}
          edges={edges}
          rankMargin={300}
          nodeMargin={20}
          curve="curveMonotoneX"
          direction={DependencyGraphTypes.Direction.BOTTOM_TOP}
          renderNode={RenderNode}
          className={classes.graph}
          fit="contain"
        />
  
        <Typography
          variant="caption"
          color="textSecondary"
          display="block"
          className={classes.legend}
        >
          <ZoomOutMap className="icon" /> Use pinch &amp; zoom to move around the
          diagram.
        </Typography>
      </div>
    );
  }