import React from 'react';
import { makeStyles } from '@material-ui/core';

const iconStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 28,
  },
  path: {
    fill: '#7df3e1',
  },
});

export const LogoIcon = () => {
  const classes = iconStyles();

  return (
    <svg
      className={classes.svg}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 2501 1004"
    >
      <path d="M1625.94 0V285.883C1587.66 247.612 1528.83 227.415 1470.73 227.415C1326.13 227.231 1215.46 344.812 1215.46 489.229C1215.46 633.646 1326.13 751.227 1470.27 751.227C1528.37 751.227 1590.15 728.633 1625.47 692.667V741.544H1732.27V0H1625.94ZM1475.99 644.528C1390.4 644.528 1327.88 574.901 1327.88 489.229C1327.88 403.556 1390.68 334.022 1475.99 334.022C1516.51 335.01 1555.03 351.8 1583.34 380.808C1611.65 409.816 1627.5 448.742 1627.5 489.275C1627.5 529.807 1611.65 568.734 1583.34 597.742C1555.03 626.75 1516.51 643.54 1475.99 644.528Z" fill="#0FB9B6" />
      <path d="M1128.22 248.448H1021.43V741.826H1128.22V248.448Z" fill="#0FB9B6" />
      <path d="M1074.83 144.144C1112.62 144.144 1143.25 113.508 1143.25 75.7163C1143.25 37.9248 1112.62 7.28882 1074.83 7.28882C1037.04 7.28882 1006.4 37.9248 1006.4 75.7163C1006.4 113.508 1037.04 144.144 1074.83 144.144Z" fill="#0FB9B6" />
      <path d="M403.372 248.448V285.889C359.751 248.263 312.811 227.053 254.712 227.053C110.203 227.053 0 344.541 0 489.051C0 633.56 110.664 751.049 254.712 751.049C312.811 751.049 359.751 728.455 403.372 692.304V741.458C403.347 782.622 386.99 822.095 357.891 851.211C328.792 880.327 289.329 896.708 248.165 896.757C209.568 896.658 172.413 882.079 144.048 855.903L83.1827 942.867C129.387 981.767 187.766 1003.24 248.165 1003.55C317.629 1003.47 384.226 975.848 433.344 926.729C482.463 877.611 510.089 811.014 510.163 741.55V248.448H403.372ZM253.698 644.534C168.118 644.534 106.146 574.908 106.146 489.235C106.146 403.562 168.486 333.936 253.698 333.936C294.219 334.924 332.747 351.714 361.057 380.722C389.366 409.73 405.213 448.656 405.213 489.189C405.213 529.722 389.366 568.648 361.057 597.656C332.747 626.664 294.219 643.454 253.698 644.442V644.534Z" fill="#0FB9B6" />
      <path d="M2472.24 0.189941H2330.13L2155.37 249.646L1980.71 0.189941H1838.51L2084.27 351.18L1810.75 741.826H1952.95L2155.37 452.715L2357.89 741.826H2500L2226.48 351.18L2472.24 0.189941Z" fill="#0FB9B6" />
      <path d="M899.426 344.342C907.063 344.443 914.68 345.152 922.204 346.463L949.87 242.991C933.151 239.541 916.128 237.78 899.057 237.735C841.984 238.511 787.017 259.413 743.85 296.756V248.432H637.428V741.811H744.127V499.641C744.151 458.476 760.508 419.003 789.607 389.887C818.706 360.771 858.169 344.39 899.333 344.342H899.426Z" fill="#0FB9B6" />
    </svg>
  );
};

const naviStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 30,
  },
});
export const LogoNavi = () => {
  const classes = naviStyles();

  return (
    <svg
      className={classes.svg}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 2501 1004"
    >
      <path d="M1625.94 0V285.883C1587.66 247.612 1528.83 227.415 1470.73 227.415C1326.13 227.231 1215.46 344.812 1215.46 489.229C1215.46 633.646 1326.13 751.227 1470.27 751.227C1528.37 751.227 1590.15 728.633 1625.47 692.667V741.544H1732.27V0H1625.94ZM1475.99 644.528C1390.4 644.528 1327.88 574.901 1327.88 489.229C1327.88 403.556 1390.68 334.022 1475.99 334.022C1516.51 335.01 1555.03 351.8 1583.34 380.808C1611.65 409.816 1627.5 448.742 1627.5 489.275C1627.5 529.807 1611.65 568.734 1583.34 597.742C1555.03 626.75 1516.51 643.54 1475.99 644.528Z" fill="#0FB9B6" />
      <path d="M1128.22 248.448H1021.43V741.826H1128.22V248.448Z" fill="#0FB9B6" />
      <path d="M1074.83 144.144C1112.62 144.144 1143.25 113.508 1143.25 75.7163C1143.25 37.9248 1112.62 7.28882 1074.83 7.28882C1037.04 7.28882 1006.4 37.9248 1006.4 75.7163C1006.4 113.508 1037.04 144.144 1074.83 144.144Z" fill="#0FB9B6" />
      <path d="M403.372 248.448V285.889C359.751 248.263 312.811 227.053 254.712 227.053C110.203 227.053 0 344.541 0 489.051C0 633.56 110.664 751.049 254.712 751.049C312.811 751.049 359.751 728.455 403.372 692.304V741.458C403.347 782.622 386.99 822.095 357.891 851.211C328.792 880.327 289.329 896.708 248.165 896.757C209.568 896.658 172.413 882.079 144.048 855.903L83.1827 942.867C129.387 981.767 187.766 1003.24 248.165 1003.55C317.629 1003.47 384.226 975.848 433.344 926.729C482.463 877.611 510.089 811.014 510.163 741.55V248.448H403.372ZM253.698 644.534C168.118 644.534 106.146 574.908 106.146 489.235C106.146 403.562 168.486 333.936 253.698 333.936C294.219 334.924 332.747 351.714 361.057 380.722C389.366 409.73 405.213 448.656 405.213 489.189C405.213 529.722 389.366 568.648 361.057 597.656C332.747 626.664 294.219 643.454 253.698 644.442V644.534Z" fill="#0FB9B6" />
      <path d="M2472.24 0.189941H2330.13L2155.37 249.646L1980.71 0.189941H1838.51L2084.27 351.18L1810.75 741.826H1952.95L2155.37 452.715L2357.89 741.826H2500L2226.48 351.18L2472.24 0.189941Z" fill="#0FB9B6" />
      <path d="M899.426 344.342C907.063 344.443 914.68 345.152 922.204 346.463L949.87 242.991C933.151 239.541 916.128 237.78 899.057 237.735C841.984 238.511 787.017 259.413 743.85 296.756V248.432H637.428V741.811H744.127V499.641C744.151 458.476 760.508 419.003 789.607 389.887C818.706 360.771 858.169 344.39 899.333 344.342H899.426Z" fill="#0FB9B6" />
    </svg>
  );
};


const fullStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 100,
  },
});
export const LogoFull = () => {
  const classes = fullStyles();

  return (
    <svg
      className={classes.svg}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 2501 1004"
    >
      <path d="M1625.94 0V285.883C1587.66 247.612 1528.83 227.415 1470.73 227.415C1326.13 227.231 1215.46 344.812 1215.46 489.229C1215.46 633.646 1326.13 751.227 1470.27 751.227C1528.37 751.227 1590.15 728.633 1625.47 692.667V741.544H1732.27V0H1625.94ZM1475.99 644.528C1390.4 644.528 1327.88 574.901 1327.88 489.229C1327.88 403.556 1390.68 334.022 1475.99 334.022C1516.51 335.01 1555.03 351.8 1583.34 380.808C1611.65 409.816 1627.5 448.742 1627.5 489.275C1627.5 529.807 1611.65 568.734 1583.34 597.742C1555.03 626.75 1516.51 643.54 1475.99 644.528Z" fill="#0FB9B6" />
      <path d="M1128.22 248.448H1021.43V741.826H1128.22V248.448Z" fill="#0FB9B6" />
      <path d="M1074.83 144.144C1112.62 144.144 1143.25 113.508 1143.25 75.7163C1143.25 37.9248 1112.62 7.28882 1074.83 7.28882C1037.04 7.28882 1006.4 37.9248 1006.4 75.7163C1006.4 113.508 1037.04 144.144 1074.83 144.144Z" fill="#0FB9B6" />
      <path d="M403.372 248.448V285.889C359.751 248.263 312.811 227.053 254.712 227.053C110.203 227.053 0 344.541 0 489.051C0 633.56 110.664 751.049 254.712 751.049C312.811 751.049 359.751 728.455 403.372 692.304V741.458C403.347 782.622 386.99 822.095 357.891 851.211C328.792 880.327 289.329 896.708 248.165 896.757C209.568 896.658 172.413 882.079 144.048 855.903L83.1827 942.867C129.387 981.767 187.766 1003.24 248.165 1003.55C317.629 1003.47 384.226 975.848 433.344 926.729C482.463 877.611 510.089 811.014 510.163 741.55V248.448H403.372ZM253.698 644.534C168.118 644.534 106.146 574.908 106.146 489.235C106.146 403.562 168.486 333.936 253.698 333.936C294.219 334.924 332.747 351.714 361.057 380.722C389.366 409.73 405.213 448.656 405.213 489.189C405.213 529.722 389.366 568.648 361.057 597.656C332.747 626.664 294.219 643.454 253.698 644.442V644.534Z" fill="#0FB9B6" />
      <path d="M2472.24 0.189941H2330.13L2155.37 249.646L1980.71 0.189941H1838.51L2084.27 351.18L1810.75 741.826H1952.95L2155.37 452.715L2357.89 741.826H2500L2226.48 351.18L2472.24 0.189941Z" fill="#0FB9B6" />
      <path d="M899.426 344.342C907.063 344.443 914.68 345.152 922.204 346.463L949.87 242.991C933.151 239.541 916.128 237.78 899.057 237.735C841.984 238.511 787.017 259.413 743.85 296.756V248.432H637.428V741.811H744.127V499.641C744.151 458.476 760.508 419.003 789.607 389.887C818.706 360.771 858.169 344.39 899.333 344.342H899.426Z" fill="#0FB9B6" />
    </svg>
  );
};
