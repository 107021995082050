import React from 'react';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { CatalogKindExploreContent, ExploreLayout } from '@backstage/plugin-explore';
import { ToolExplorerContent } from './ToolExplorerContent';
import { GroupsExplorerContent } from './GroupCard';

export const ExplorePage = () => {
  const configApi = useApi(configApiRef);
  const organizationName =
    configApi.getOptionalString('organization.name') ?? 'Backstage';

  return (
    <ExploreLayout
      title={`Explore the ${organizationName} ecosystem`}
      subtitle="Discover solutions available in your ecosystem"
    >
      <ExploreLayout.Route path="components" title="Components">
        <CatalogKindExploreContent kind="component" />
      </ExploreLayout.Route>
      <ExploreLayout.Route path="resources" title="Resources">
        <CatalogKindExploreContent kind="resource" />
      </ExploreLayout.Route>
      <ExploreLayout.Route path="groups" title="GitHub Groups">
        <GroupsExplorerContent />
      </ExploreLayout.Route>
      <ExploreLayout.Route path="tools" title="Tools">
        <ToolExplorerContent />
      </ExploreLayout.Route>
    </ExploreLayout>
  );
};

export const explorePage = <ExplorePage />;
