import {
    Alert,
    CostInsightsApi,
    DEFAULT_DATE_FORMAT,
    ProductInsightsOptions,
    Duration,
} from "@internal/backstage-plugin-cost-insights";
import {
    CostExplorerClient,
    GetCostAndUsageCommand,
    GetCostAndUsageCommandOutput,
} from "@aws-sdk/client-cost-explorer";
import { Interval } from "repeating-interval";
import { DateTime, Duration as LuxonDuration } from 'luxon';
import {
    Group,
    MetricData,
    Project,
    Cost,
    ChangeStatistic,
    Entity,
    Trendline,
    DateAggregation,
} from '@backstage/plugin-cost-insights-common';
import regression, { DataPoint } from 'regression';
import AWS from 'aws-sdk';
import { Config } from "@backstage/config";

export class CostInsightsClient implements CostInsightsApi {
    private backendBaseUrl: string
    private credentials: AWS.Credentials

    constructor(config: Config) {
        this.backendBaseUrl = config.get('backend.baseUrl')
        this.credentials = new AWS.Credentials({
            accessKeyId: "",
            secretAccessKey: "",
            sessionToken: "",
        })
    }

    async generateCredentials() {
        if (this.credentials.accessKeyId == "") {
            const resp = await (
                await fetch(`${this.backendBaseUrl}/api/aws-costs/credentials`, {})
            ).json();

            this.credentials = new AWS.Credentials({
                accessKeyId: resp.AccessKeyId,
                secretAccessKey: resp.SecretAccessKey,
                sessionToken: resp.SessionToken,
            });
        }
        return this.credentials
    }

    async getApplianceMetrics() {
        const resp = await (
            await fetch(`${this.backendBaseUrl}/api/aws-costs/appliance-metrics`, {})
        ).json();

        return resp
    }

    async getAwsGroupCostAndUsage(
        project: string,
        start: string,
        end: string,
    ): Promise<GetCostAndUsageCommandOutput> {
        let credentials = await this.generateCredentials();
        const client = new CostExplorerClient({
            region: "us-east-1",
            credentials: {
                accessKeyId: credentials.accessKeyId,
                secretAccessKey: credentials.secretAccessKey,
                sessionToken: credentials.sessionToken,
            },
        });

        const filter = {
            Filter: {
                And: [
                    {
                        Not: {
                            Dimensions: {
                                Key: "SERVICE",
                                Values: ["Tax"]
                            }
                        },
                    }, {
                        Dimensions: {
                            Key: "LINKED_ACCOUNT",
                            Values: [project]
                        }
                    }
                ]
            }
        }
        const input = {
            TimePeriod: {
                Start: start,
                End: end,
            },
            Metrics: ["AMORTIZED_COST"],
            Granularity: "DAILY",
        };
        const filter_input = {
            ...input,
            ...filter,
        };

        const command = new GetCostAndUsageCommand(filter_input);
        const data = await client.send(command);
        return data;
    }

    async getAwsServiceCostAndUsage(
        start: string,
        end: string,
    ): Promise<GetCostAndUsageCommandOutput> {
        let credentials = await this.generateCredentials();
        const client = new CostExplorerClient({
            region: "us-east-1",
            credentials: {
                accessKeyId: credentials.accessKeyId,
                secretAccessKey: credentials.secretAccessKey,
                sessionToken: credentials.sessionToken,
            },
        });

        const filter = {
            Filter: {
                And: [
                    {
                        Not: {
                            Dimensions: {
                                Key: "SERVICE",
                                Values: ["Tax"]
                            }
                        },
                    },
                    {
                        Or: [
                            {
                                Dimensions: {
                                    Key: "SERVICE",
                                    Values: [
                                        "Amazon Relational Database Service"
                                    ]
                                }
                            },
                            {
                                Dimensions: {
                                    Key: "SERVICE",
                                    Values: [
                                        "EC2 - Other"
                                    ]
                                }
                            },
                            {
                                Dimensions: {
                                    Key: "SERVICE",
                                    Values: [
                                        "Amazon Simple Storage Service"
                                    ]
                                }
                            },
                            {
                                Dimensions: {
                                    Key: "SERVICE",
                                    Values: [
                                        "Amazon Elastic Compute Cloud - Compute"
                                    ]
                                }
                            },
                            {
                                Dimensions: {
                                    Key: "SERVICE",
                                    Values: [
                                        "Amazon Elastic Load Balancing"
                                    ]
                                }
                            },
                            {
                                Dimensions: {
                                    Key: "SERVICE",
                                    Values: [
                                        "Amazon Kinesis"
                                    ]
                                }
                            },
                            {
                                Dimensions: {
                                    Key: "SERVICE",
                                    Values: [
                                        "AmazonCloudWatch"
                                    ]
                                }
                            },
                            {
                                Dimensions: {
                                    Key: "SERVICE",
                                    Values: [
                                        "Amazon Virtual Private Cloud"
                                    ]
                                }
                            },
                            {
                                Dimensions: {
                                    Key: "SERVICE",
                                    Values: [
                                        "Amazon ElastiCache"
                                    ]
                                }
                            },
                            {
                                Dimensions: {
                                    Key: "SERVICE",
                                    Values: [
                                        "Amazon Timestream"
                                    ]
                                }
                            },
                            {
                                Dimensions: {
                                    Key: "SERVICE",
                                    Values: [
                                        "AWS Lambda"
                                    ]
                                }
                            },
                            {
                                Dimensions: {
                                    Key: "SERVICE",
                                    Values: [
                                        "Amazon Elastic File System"
                                    ]
                                }
                            },
                            {
                                Dimensions: {
                                    Key: "SERVICE",
                                    Values: [
                                        "AWS Key Management Service"
                                    ]
                                }
                            },
                            {
                                Dimensions: {
                                    Key: "SERVICE",
                                    Values: [
                                        "Amazon MemoryDB"
                                    ]
                                }
                            },
                            {
                                Dimensions: {
                                    Key: "SERVICE",
                                    Values: [
                                        "Amazon EC2 Container Registry (ECR)"
                                    ]
                                }
                            },
                            {
                                Dimensions: {
                                    Key: "SERVICE",
                                    Values: [
                                        "Amazon Elastic Container Service for Kubernetes"
                                    ]
                                }
                            },
                            {
                                Dimensions: {
                                    Key: "SERVICE",
                                    Values: [
                                        "AWS Config"
                                    ]
                                }
                            },
                            {
                                Dimensions: {
                                    Key: "SERVICE",
                                    Values: [
                                        "AWS CloudTrail"
                                    ]
                                }
                            },
                            {
                                Dimensions: {
                                    Key: "SERVICE",
                                    Values: [
                                        "Amazon Simple Queue Service"
                                    ]
                                }
                            },
                            {
                                Dimensions: {
                                    Key: "SERVICE",
                                    Values: [
                                        "Amazon Registrar"
                                    ]
                                }
                            },
                            {
                                Dimensions: {
                                    Key: "SERVICE",
                                    Values: [
                                        "Amazon Elastic Container Service"
                                    ]
                                }
                            },
                            {
                                Dimensions: {
                                    Key: "SERVICE",
                                    Values: [
                                        "Amazon MQ"
                                    ]
                                }
                            },
                            {
                                Dimensions: {
                                    Key: "SERVICE",
                                    Values: [
                                        "Amazon API Gateway"
                                    ]
                                }
                            },
                            {
                                Dimensions: {
                                    Key: "SERVICE",
                                    Values: [
                                        "Amazon Route 53"
                                    ]
                                }
                            },
                            {
                                Dimensions: {
                                    Key: "SERVICE",
                                    Values: [
                                        "Amazon QuickSight"
                                    ]
                                }
                            },
                            {
                                Dimensions: {
                                    Key: "SERVICE",
                                    Values: [
                                        "Amazon Elastic Container Registry Public"
                                    ]
                                }
                            },
                            {
                                Dimensions: {
                                    Key: "SERVICE",
                                    Values: [
                                        "Amazon DynamoDB"
                                    ]
                                }
                            }
                        ]
                    }
                ]
            }
        }
        const input = {
            TimePeriod: {
                Start: start,
                End: end,
            },
            Metrics: ["AMORTIZED_COST"],
            GroupBy: [
                {
                    Type: "DIMENSION",
                    Key: "SERVICE"
                }
            ],
            Granularity: "DAILY",
        };
        const filter_input = {
            ...input,
            ...filter,
        };

        const command = new GetCostAndUsageCommand(filter_input);
        const data = await client.send(command);
        return data;
    }

    async getAwsServiceCostAndUsagePerProject(
        start: string,
        end: string,
        project: string,
    ): Promise<GetCostAndUsageCommandOutput> {
        let credentials = await this.generateCredentials();
        const client = new CostExplorerClient({
            region: "us-east-1",
            credentials: {
                accessKeyId: credentials.accessKeyId,
                secretAccessKey: credentials.secretAccessKey,
                sessionToken: credentials.sessionToken,
            },
        });

        const filter = {
            Filter: {
                And: [
                    {
                        Dimensions: {
                            Key: "LINKED_ACCOUNT",
                            Values: [project]
                        }
                    },
                    {
                        Not: {
                            Dimensions: {
                                Key: "SERVICE",
                                Values: ["Tax"]
                            }
                        },
                    },
                    {
                        Or: [
                            {
                                Dimensions: {
                                    Key: "SERVICE",
                                    Values: [
                                        "Amazon Relational Database Service"
                                    ]
                                }
                            },
                            {
                                Dimensions: {
                                    Key: "SERVICE",
                                    Values: [
                                        "EC2 - Other"
                                    ]
                                }
                            },
                            {
                                Dimensions: {
                                    Key: "SERVICE",
                                    Values: [
                                        "Amazon Simple Storage Service"
                                    ]
                                }
                            },
                            {
                                Dimensions: {
                                    Key: "SERVICE",
                                    Values: [
                                        "Amazon Elastic Compute Cloud - Compute"
                                    ]
                                }
                            },
                            {
                                Dimensions: {
                                    Key: "SERVICE",
                                    Values: [
                                        "Amazon Elastic Load Balancing"
                                    ]
                                }
                            },
                            {
                                Dimensions: {
                                    Key: "SERVICE",
                                    Values: [
                                        "Amazon Kinesis"
                                    ]
                                }
                            },
                            {
                                Dimensions: {
                                    Key: "SERVICE",
                                    Values: [
                                        "AmazonCloudWatch"
                                    ]
                                }
                            },
                            {
                                Dimensions: {
                                    Key: "SERVICE",
                                    Values: [
                                        "Amazon Virtual Private Cloud"
                                    ]
                                }
                            },
                            {
                                Dimensions: {
                                    Key: "SERVICE",
                                    Values: [
                                        "Amazon ElastiCache"
                                    ]
                                }
                            },
                            {
                                Dimensions: {
                                    Key: "SERVICE",
                                    Values: [
                                        "Amazon Timestream"
                                    ]
                                }
                            },
                            {
                                Dimensions: {
                                    Key: "SERVICE",
                                    Values: [
                                        "AWS Lambda"
                                    ]
                                }
                            },
                            {
                                Dimensions: {
                                    Key: "SERVICE",
                                    Values: [
                                        "Amazon Elastic File System"
                                    ]
                                }
                            },
                            {
                                Dimensions: {
                                    Key: "SERVICE",
                                    Values: [
                                        "AWS Key Management Service"
                                    ]
                                }
                            },
                            {
                                Dimensions: {
                                    Key: "SERVICE",
                                    Values: [
                                        "Amazon MemoryDB"
                                    ]
                                }
                            },
                            {
                                Dimensions: {
                                    Key: "SERVICE",
                                    Values: [
                                        "Amazon EC2 Container Registry (ECR)"
                                    ]
                                }
                            },
                            {
                                Dimensions: {
                                    Key: "SERVICE",
                                    Values: [
                                        "Amazon Elastic Container Service for Kubernetes"
                                    ]
                                }
                            },
                            {
                                Dimensions: {
                                    Key: "SERVICE",
                                    Values: [
                                        "AWS Config"
                                    ]
                                }
                            },
                            {
                                Dimensions: {
                                    Key: "SERVICE",
                                    Values: [
                                        "AWS CloudTrail"
                                    ]
                                }
                            },
                            {
                                Dimensions: {
                                    Key: "SERVICE",
                                    Values: [
                                        "Amazon Simple Queue Service"
                                    ]
                                }
                            },
                            {
                                Dimensions: {
                                    Key: "SERVICE",
                                    Values: [
                                        "Amazon Registrar"
                                    ]
                                }
                            },
                            {
                                Dimensions: {
                                    Key: "SERVICE",
                                    Values: [
                                        "Amazon Elastic Container Service"
                                    ]
                                }
                            },
                            {
                                Dimensions: {
                                    Key: "SERVICE",
                                    Values: [
                                        "Amazon MQ"
                                    ]
                                }
                            },
                            {
                                Dimensions: {
                                    Key: "SERVICE",
                                    Values: [
                                        "Amazon API Gateway"
                                    ]
                                }
                            },
                            {
                                Dimensions: {
                                    Key: "SERVICE",
                                    Values: [
                                        "Amazon Route 53"
                                    ]
                                }
                            },
                            {
                                Dimensions: {
                                    Key: "SERVICE",
                                    Values: [
                                        "Amazon QuickSight"
                                    ]
                                }
                            },
                            {
                                Dimensions: {
                                    Key: "SERVICE",
                                    Values: [
                                        "Amazon Elastic Container Registry Public"
                                    ]
                                }
                            },
                            {
                                Dimensions: {
                                    Key: "SERVICE",
                                    Values: [
                                        "Amazon DynamoDB"
                                    ]
                                }
                            }
                        ]
                    }
                ]
            }
        }
        const input = {
            TimePeriod: {
                Start: start,
                End: end,
            },
            Metrics: ["AMORTIZED_COST"],
            GroupBy: [
                {
                    Type: "DIMENSION",
                    Key: "SERVICE"
                }
            ],
            Granularity: "DAILY",
        };
        const filter_input = {
            ...input,
            ...filter,
        };

        const command = new GetCostAndUsageCommand(filter_input);
        const data = await client.send(command);
        return data;
    }

    async getLastCompleteBillingDate(): Promise<string> {
        return Promise.resolve(
            DateTime.now().minus({ days: 1 }).toFormat(DEFAULT_DATE_FORMAT)
        );
    }

    async getUserGroups(_: string): Promise<Group[]> {
        // We just consider one group for all users for now
        return [{ id: "gridX" }];
    }

    async getGroupProjects(_: string): Promise<Project[]> {
        // Don't distinguish between groups for now
        return [
            {
                id: "108014196837",
                name: "Development"
            },
            {
                id: "981820318686",
                name: "XENON-Staging",
            },
            {
                id: "590777358184",
                name: "XENON-Production",
            },
            {
                id: "465084495259",
                name: "DS-Staging",
            },
            {
                id: "485611583707",
                name: "DS-Production",
            },
            {
                id: "165862836797",
                name: "Virtual-GridBoxes",
            },
            {
                id: "362654467280",
                name: "Business Intelligence",
            },
            {
                id: "683996509277",
                name: "SRE Production",
            },
            {
                id: "336424666827",
                name: "Optifra Production",
            },
        ];
    }

    async getAlerts(_: string): Promise<Alert[]> {
        return [];
    }

    async getDailyMetricData(
        _: string,
        intervals: string,
    ): Promise<MetricData> {
        let tempAggregation: DateAggregation[] = []

        const moment = require("moment");
        const rep_interval = new Interval(intervals);
        let start = moment(rep_interval.start).clone();
        let end = moment()
        for (let _i = 0; _i < rep_interval.repetitions; _i++) {
            start = start.subtract(rep_interval.duration);
        }

        const metrics = await this.getApplianceMetrics()

        // Following numbers are taken from metabase and are linear downsampled on a daily level 
        for (var m = moment(start); m.isBefore(end); m.add(1, 'days')) {
            for (let e of metrics) {
                if(moment(m.format('YYYY-MM-DD')).isSame(e.date_trunc)){
                    tempAggregation = [
                        ...tempAggregation,
                        {
                            amount: e.sum.replace(/,/, ''),
                            date: m.format('YYYY-MM-DD'),
                        },
                    ];
                }
            };
        }

        const metricdata: MetricData = {
            id: "assets",
            format: "number",
            aggregation: structuredClone(tempAggregation),
            change: changeOf(tempAggregation)
        };

        return metricdata;
    }

    async getGroupDailyCost(group: string, intervals: string): Promise<Cost> {
        const cost: Cost = {
            id: group,
            aggregation: [],
            change: {
                ratio: 0,
                amount: 0,
            },
            groupedCosts: {}
        };

        const groupedCosts: Record<string, Cost[]> = {}

        let timeRanges = this.splitTimeRangeIntoEqualIntervals(intervals)

        let rdsAggregation: DateAggregation[] = []
        let ec2OtherAggregation: DateAggregation[] = []
        let s3Aggregation: DateAggregation[] = []
        let ec2ComputeAggregation: DateAggregation[] = []
        let ec2ELBAggregation: DateAggregation[] = []
        let kinesisAggregation: DateAggregation[] = []
        let elasticacheAggregation: DateAggregation[] = []
        let memoryDBAggregation: DateAggregation[] = []
        let vpcAggregation: DateAggregation[] = []
        let cloudwatchAggregation: DateAggregation[] = []
        let lambdaAggregation: DateAggregation[] = []
        let efsAggregation: DateAggregation[] = []
        let ecrAggregation: DateAggregation[] = []
        let kmsAggregation: DateAggregation[] = []
        let otherAggregation: DateAggregation[] = []

        for (const r of timeRanges){
            const data = await this.getAwsServiceCostAndUsage(
                r.start.format("YYYY-MM-DD"),
                r.end.format("YYYY-MM-DD"),
            );
            
            // Append to costs aggregation
            data.ResultsByTime?.map((value) => {
                let time = value.TimePeriod?.Start
                let groups = value.Groups

                if (groups != undefined && time != undefined) {
                    let othersum = 0
                    for (const g of groups) {
                        if (g.Keys != undefined && g.Metrics != undefined && g.Metrics["AmortizedCost"].Amount != undefined) {
                            if (g.Keys[0] == "Amazon Relational Database Service") {
                                rdsAggregation = [
                                    ...rdsAggregation,
                                    {
                                        amount: parseFloat(g.Metrics["AmortizedCost"].Amount),
                                        date: time,
                                    },
                                ];
                            } else if (g.Keys[0] == "EC2 - Other") {
                                ec2OtherAggregation = [
                                    ...ec2OtherAggregation,
                                    {
                                        amount: parseFloat(g.Metrics["AmortizedCost"].Amount),
                                        date: time,
                                    },
                                ];
                            } else if (g.Keys[0] == "Amazon Simple Storage Service") {
                                s3Aggregation = [
                                    ...s3Aggregation,
                                    {
                                        amount: parseFloat(g.Metrics["AmortizedCost"].Amount),
                                        date: time,
                                    },
                                ];
                            } else if (g.Keys[0] == "Amazon Elastic Compute Cloud - Compute") {
                                ec2ComputeAggregation = [
                                    ...ec2ComputeAggregation,
                                    {
                                        amount: parseFloat(g.Metrics["AmortizedCost"].Amount),
                                        date: time,
                                    },
                                ];
                            } else if (g.Keys[0] == "Amazon Elastic Load Balancing") {
                                ec2ELBAggregation = [
                                    ...ec2ELBAggregation,
                                    {
                                        amount: parseFloat(g.Metrics["AmortizedCost"].Amount),
                                        date: time,
                                    },
                                ];
                            } else if (g.Keys[0] == "Amazon Kinesis") {
                                kinesisAggregation = [
                                    ...kinesisAggregation,
                                    {
                                        amount: parseFloat(g.Metrics["AmortizedCost"].Amount),
                                        date: time,
                                    },
                                ];
                            } else if (g.Keys[0] == "Amazon ElastiCache") {
                                elasticacheAggregation = [
                                    ...elasticacheAggregation,
                                    {
                                        amount: parseFloat(g.Metrics["AmortizedCost"].Amount),
                                        date: time,
                                    },
                                ];
                            } else if (g.Keys[0] == "Amazon MemoryDB") {
                                memoryDBAggregation = [
                                    ...memoryDBAggregation,
                                    {
                                        amount: parseFloat(g.Metrics["AmortizedCost"].Amount),
                                        date: time,
                                    },
                                ];
                            } else if (g.Keys[0] == "Amazon Virtual Private Cloud") {
                                vpcAggregation = [
                                    ...vpcAggregation,
                                    {
                                        amount: parseFloat(g.Metrics["AmortizedCost"].Amount),
                                        date: time,
                                    },
                                ];
                            } else if (g.Keys[0] == "AmazonCloudWatch") {
                                cloudwatchAggregation = [
                                    ...cloudwatchAggregation,
                                    {
                                        amount: parseFloat(g.Metrics["AmortizedCost"].Amount),
                                        date: time,
                                    },
                                ];
                            } else if (g.Keys[0] == "Amazon Elastic File System") {
                                efsAggregation = [
                                    ...efsAggregation,
                                    {
                                        amount: parseFloat(g.Metrics["AmortizedCost"].Amount),
                                        date: time,
                                    },
                                ];
                            } else if (g.Keys[0] == "Amazon EC2 Container Registry (ECR)") {
                                ecrAggregation = [
                                    ...ecrAggregation,
                                    {
                                        amount: parseFloat(g.Metrics["AmortizedCost"].Amount),
                                        date: time,
                                    },
                                ];
                            } else if (g.Keys[0] == "AWS Key Management Service") {
                                kmsAggregation = [
                                    ...kmsAggregation,
                                    {
                                        amount: parseFloat(g.Metrics["AmortizedCost"].Amount),
                                        date: time,
                                    },
                                ];
                            } else if (g.Keys[0] == "AWS Lambda") {
                                lambdaAggregation = [
                                    ...lambdaAggregation,
                                    {
                                        amount: parseFloat(g.Metrics["AmortizedCost"].Amount),
                                        date: time,
                                    },
                                ];
                            } else {
                                othersum = othersum + parseFloat(g.Metrics["AmortizedCost"].Amount)
                            }
                        }
                    }
                    otherAggregation = [
                        ...otherAggregation,
                        {
                            amount: othersum,
                            date: time,
                        },
                    ];
                }
            });
        }

        const group_projects = await this.getGroupProjects(group);
        let groupCostArray: Cost[] = []
        let serviceCostArray: Cost[] = []
        
        for (const project of group_projects) {
            let tempAggregation: DateAggregation[] = []
            for (const r of timeRanges){
                const data = await this.getAwsGroupCostAndUsage(
                    project["id"],
                    r.start.format("YYYY-MM-DD"),
                    r.end.format("YYYY-MM-DD"),
                );
    
    
                // Append to costs aggregation
                data.ResultsByTime?.map((value) => {
                    let t = value.Total
                    let time = value.TimePeriod?.Start
                    if (time != undefined && t != undefined && t["AmortizedCost"].Amount != undefined) {
                        cost.aggregation = [
                            ...cost.aggregation,
                            {
                                amount: parseFloat(t["AmortizedCost"].Amount),
                                date: time,
                            },
                        ];
                        tempAggregation = [
                            ...tempAggregation,
                            {
                                amount: parseFloat(t["AmortizedCost"].Amount),
                                date: time,
                            },
                        ];
                    }
                });
            }
            if (project["name"] != undefined) {
                let temp: Cost = {
                    id: project["name"],
                    aggregation: structuredClone(tempAggregation),
                    change: changeOf(tempAggregation),
                    trendline: trendlineOf(tempAggregation),
                };
                groupCostArray.push(temp)
            }
        }

        groupedCosts["AWS Accounts"] = groupCostArray

        let temp: Cost = {
            id: "Amazon Relational Database Service",
            aggregation: rdsAggregation,
            change: changeOf(rdsAggregation),
            trendline: trendlineOf(rdsAggregation),
        };
        serviceCostArray.push(temp)

        temp = {
            id: "EC2 - Compute",
            aggregation: ec2ComputeAggregation,
            change: changeOf(ec2ComputeAggregation),
            trendline: trendlineOf(ec2ComputeAggregation),
        };
        serviceCostArray.push(temp)

        temp = {
            id: "EC2 - ELB",
            aggregation: ec2ELBAggregation,
            change: changeOf(ec2ELBAggregation),
            trendline: trendlineOf(ec2ELBAggregation),
        };
        serviceCostArray.push(temp)

        temp = {
            id: "EC2 - Other (Data-Transfer, EBS...)",
            aggregation: ec2OtherAggregation,
            change: changeOf(ec2OtherAggregation),
            trendline: trendlineOf(ec2OtherAggregation),
        };
        serviceCostArray.push(temp)

        temp = {
            id: "S3",
            aggregation: s3Aggregation,
            change: changeOf(s3Aggregation),
            trendline: trendlineOf(s3Aggregation),
        };
        serviceCostArray.push(temp)

        temp = {
            id: "Kinesis",
            aggregation: kinesisAggregation,
            change: changeOf(kinesisAggregation),
            trendline: trendlineOf(kinesisAggregation),
        };
        serviceCostArray.push(temp)

        temp = {
            id: "Elasticache",
            aggregation: elasticacheAggregation,
            change: changeOf(elasticacheAggregation),
            trendline: trendlineOf(elasticacheAggregation),
        };
        serviceCostArray.push(temp)

        temp = {
            id: "MemoryDB",
            aggregation: memoryDBAggregation,
            change: changeOf(memoryDBAggregation),
            trendline: trendlineOf(memoryDBAggregation),
        };
        serviceCostArray.push(temp)

        temp = {
            id: "VPC",
            aggregation: vpcAggregation,
            change: changeOf(vpcAggregation),
            trendline: trendlineOf(vpcAggregation),
        };
        serviceCostArray.push(temp)

        temp = {
            id: "Cloudwatch",
            aggregation: cloudwatchAggregation,
            change: changeOf(cloudwatchAggregation),
            trendline: trendlineOf(cloudwatchAggregation),
        };
        serviceCostArray.push(temp)

        temp = {
            id: "Lambda",
            aggregation: lambdaAggregation,
            change: changeOf(lambdaAggregation),
            trendline: trendlineOf(lambdaAggregation),
        };
        serviceCostArray.push(temp)

        temp = {
            id: "EFS",
            aggregation: efsAggregation,
            change: changeOf(efsAggregation),
            trendline: trendlineOf(efsAggregation),
        };
        serviceCostArray.push(temp)

        temp = {
            id: "ECR",
            aggregation: ecrAggregation,
            change: changeOf(ecrAggregation),
            trendline: trendlineOf(ecrAggregation),
        };
        serviceCostArray.push(temp)

        temp = {
            id: "KMS",
            aggregation: kmsAggregation,
            change: changeOf(kmsAggregation),
            trendline: trendlineOf(kmsAggregation),
        };
        serviceCostArray.push(temp)

        temp = {
            id: "Other",
            aggregation: otherAggregation,
            change: changeOf(otherAggregation),
            trendline: trendlineOf(otherAggregation),
        };
        serviceCostArray.push(temp)

        groupedCosts["AWS Services"] = serviceCostArray

        // Group by dates in aggregation array head
        const days = cost.aggregation.length / group_projects.length;
        for (let i = 1; i < group_projects.length; i++) {
            for (let j = 0; j < days; j++) {
                cost.aggregation[j].amount += cost.aggregation[j + i * days].amount;
            }
        }
        // Cut aggregation tail
        cost.aggregation = cost.aggregation.slice(0, days);

        // Calculate change and trendline
        cost.change = changeOf(cost.aggregation);
        cost.trendline = trendlineOf(cost.aggregation);
        cost.groupedCosts = groupedCosts

        return cost;
    }

    splitTimeRangeIntoEqualIntervals(timeRange: string): { start: any; end: any; }[]{
        // Calculate start date from iso8601 format
        const moment = require("moment");
        const rep_interval = new Interval(timeRange);
        let start = moment(rep_interval.start).clone();
        let end = moment()
        for (let _i = 0; _i < rep_interval.repetitions; _i++) {
            start = start.subtract(rep_interval.duration);
        }

        // split intervals if max. number of datapoints is hit
        const intervalLength = (end - start) / 2
        return [...(new Array(2))]
          .map((_, i) => {
            return {
              start: moment(start + i * intervalLength),
              end: moment(start + (i + 1) * intervalLength)
            }
          })
    }

    async getProjectDailyCost(project: string, intervals: string): Promise<Cost> {
        const cost: Cost = {
            id: project,
            aggregation: [],
            change: {
                ratio: 0,
                amount: 0,
            },
            groupedCosts: {}
        };

        // Calculate start date from iso8601 format
        const moment = require("moment");
        const rep_interval = new Interval(intervals);
        let start = moment(rep_interval.start).clone();
        let end = moment()
        for (let _i = 0; _i < rep_interval.repetitions; _i++) {
            start = start.subtract(rep_interval.duration);
        }

        let timeRanges = this.splitTimeRangeIntoEqualIntervals(intervals)

        let rdsAggregation: DateAggregation[] = []
        let ec2OtherAggregation: DateAggregation[] = []
        let s3Aggregation: DateAggregation[] = []
        let ec2ComputeAggregation: DateAggregation[] = []
        let lambdaAggregation: DateAggregation[] = []
        let ec2ELBAggregation: DateAggregation[] = []
        let kinesisAggregation: DateAggregation[] = []
        let elasticacheAggregation: DateAggregation[] = []
        let memoryDBAggregation: DateAggregation[] = []
        let vpcAggregation: DateAggregation[] = []
        let cloudwatchAggregation: DateAggregation[] = []
        let efsAggregation: DateAggregation[] = []
        let ecrAggregation: DateAggregation[] = []
        let kmsAggregation: DateAggregation[] = []
        let otherAggregation: DateAggregation[] = []

        for (const r of timeRanges){
            const serviceData = await this.getAwsServiceCostAndUsagePerProject(
                r.start.format("YYYY-MM-DD"),
                r.end.format("YYYY-MM-DD"),
                project,
            );
    
            // Append to costs aggregation
            serviceData.ResultsByTime?.map((value) => {
                let time = value.TimePeriod?.Start
                let groups = value.Groups
    
                if (groups != undefined && time != undefined) {
                    let othersum = 0
                    for (const g of groups) {
                        if (g.Keys != undefined && g.Metrics != undefined && g.Metrics["AmortizedCost"].Amount != undefined) {
                            if (g.Keys[0] == "Amazon Relational Database Service") {
                                rdsAggregation = [
                                    ...rdsAggregation,
                                    {
                                        amount: parseFloat(g.Metrics["AmortizedCost"].Amount),
                                        date: time,
                                    },
                                ];
                            } else if (g.Keys[0] == "EC2 - Other") {
                                ec2OtherAggregation = [
                                    ...ec2OtherAggregation,
                                    {
                                        amount: parseFloat(g.Metrics["AmortizedCost"].Amount),
                                        date: time,
                                    },
                                ];
                            } else if (g.Keys[0] == "Amazon Simple Storage Service") {
                                s3Aggregation = [
                                    ...s3Aggregation,
                                    {
                                        amount: parseFloat(g.Metrics["AmortizedCost"].Amount),
                                        date: time,
                                    },
                                ];
                            } else if (g.Keys[0] == "Amazon Elastic Compute Cloud - Compute") {
                                ec2ComputeAggregation = [
                                    ...ec2ComputeAggregation,
                                    {
                                        amount: parseFloat(g.Metrics["AmortizedCost"].Amount),
                                        date: time,
                                    },
                                ];
                            } else if (g.Keys[0] == "Amazon Elastic Load Balancing") {
                                ec2ELBAggregation = [
                                    ...ec2ELBAggregation,
                                    {
                                        amount: parseFloat(g.Metrics["AmortizedCost"].Amount),
                                        date: time,
                                    },
                                ];
                            } else if (g.Keys[0] == "Amazon Kinesis") {
                                kinesisAggregation = [
                                    ...kinesisAggregation,
                                    {
                                        amount: parseFloat(g.Metrics["AmortizedCost"].Amount),
                                        date: time,
                                    },
                                ];
                            } else if (g.Keys[0] == "Amazon ElastiCache") {
                                elasticacheAggregation = [
                                    ...elasticacheAggregation,
                                    {
                                        amount: parseFloat(g.Metrics["AmortizedCost"].Amount),
                                        date: time,
                                    },
                                ];
                            } else if (g.Keys[0] == "Amazon MemoryDB") {
                                memoryDBAggregation = [
                                    ...memoryDBAggregation,
                                    {
                                        amount: parseFloat(g.Metrics["AmortizedCost"].Amount),
                                        date: time,
                                    },
                                ];
                            } else if (g.Keys[0] == "Amazon Virtual Private Cloud") {
                                vpcAggregation = [
                                    ...vpcAggregation,
                                    {
                                        amount: parseFloat(g.Metrics["AmortizedCost"].Amount),
                                        date: time,
                                    },
                                ];
                            } else if (g.Keys[0] == "AmazonCloudWatch") {
                                cloudwatchAggregation = [
                                    ...cloudwatchAggregation,
                                    {
                                        amount: parseFloat(g.Metrics["AmortizedCost"].Amount),
                                        date: time,
                                    },
                                ];
                            } else if (g.Keys[0] == "Amazon Elastic File System") {
                                efsAggregation = [
                                    ...efsAggregation,
                                    {
                                        amount: parseFloat(g.Metrics["AmortizedCost"].Amount),
                                        date: time,
                                    },
                                ];
                            } else if (g.Keys[0] == "Amazon EC2 Container Registry (ECR)") {
                                ecrAggregation = [
                                    ...ecrAggregation,
                                    {
                                        amount: parseFloat(g.Metrics["AmortizedCost"].Amount),
                                        date: time,
                                    },
                                ];
                            } else if (g.Keys[0] == "AWS Key Management Service") {
                                kmsAggregation = [
                                    ...kmsAggregation,
                                    {
                                        amount: parseFloat(g.Metrics["AmortizedCost"].Amount),
                                        date: time,
                                    },
                                ];
                            } else if (g.Keys[0] == "AWS Lambda") {
                                lambdaAggregation = [
                                    ...lambdaAggregation,
                                    {
                                        amount: parseFloat(g.Metrics["AmortizedCost"].Amount),
                                        date: time,
                                    },
                                ];
                            } else {
                                othersum = othersum + parseFloat(g.Metrics["AmortizedCost"].Amount)
                            }
                        }
                    }
                    otherAggregation = [
                        ...otherAggregation,
                        {
                            amount: othersum,
                            date: time,
                        },
                    ];
                }
            });
        }

        const data = await this.getAwsGroupCostAndUsage(
            project,
            start.format("YYYY-MM-DD"),
            end.format("YYYY-MM-DD"),
        );

        // Prepare and return valid Cost object
        data.ResultsByTime?.map((value) => {
            let t = value.Total
            let time = value.TimePeriod?.Start
            if (time != undefined && t != undefined && t["AmortizedCost"].Amount != undefined) {
                cost.aggregation = [
                    ...cost.aggregation,
                    {
                        amount: parseFloat(t["AmortizedCost"].Amount),
                        date: time,
                    },
                ];
            }
        });
        cost.change = changeOf(cost.aggregation);
        cost.trendline = trendlineOf(cost.aggregation);

        let serviceCostArray: Cost[] = []

        let temp: Cost = {
            id: "Amazon Relational Database Service",
            aggregation: rdsAggregation,
            change: changeOf(rdsAggregation),
            trendline: trendlineOf(rdsAggregation),
        };
        serviceCostArray.push(temp)

        temp = {
            id: "EC2 - Compute",
            aggregation: ec2ComputeAggregation,
            change: changeOf(ec2ComputeAggregation),
            trendline: trendlineOf(ec2ComputeAggregation),
        };
        serviceCostArray.push(temp)

        temp = {
            id: "EC2 - ELB",
            aggregation: ec2ELBAggregation,
            change: changeOf(ec2ELBAggregation),
            trendline: trendlineOf(ec2ELBAggregation),
        };
        serviceCostArray.push(temp)

        temp = {
            id: "EC2 - Other (Data-Transfer, EBS...)",
            aggregation: ec2OtherAggregation,
            change: changeOf(ec2OtherAggregation),
            trendline: trendlineOf(ec2OtherAggregation),
        };
        serviceCostArray.push(temp)

        temp = {
            id: "S3",
            aggregation: s3Aggregation,
            change: changeOf(s3Aggregation),
            trendline: trendlineOf(s3Aggregation),
        };
        serviceCostArray.push(temp)

        temp = {
            id: "Kinesis",
            aggregation: kinesisAggregation,
            change: changeOf(kinesisAggregation),
            trendline: trendlineOf(kinesisAggregation),
        };
        serviceCostArray.push(temp)

        temp = {
            id: "Elasticache",
            aggregation: elasticacheAggregation,
            change: changeOf(elasticacheAggregation),
            trendline: trendlineOf(elasticacheAggregation),
        };
        serviceCostArray.push(temp)

        temp = {
            id: "MemoryDB",
            aggregation: memoryDBAggregation,
            change: changeOf(memoryDBAggregation),
            trendline: trendlineOf(memoryDBAggregation),
        };
        serviceCostArray.push(temp)

        temp = {
            id: "VPC",
            aggregation: vpcAggregation,
            change: changeOf(vpcAggregation),
            trendline: trendlineOf(vpcAggregation),
        };
        serviceCostArray.push(temp)

        temp = {
            id: "Lambda",
            aggregation: lambdaAggregation,
            change: changeOf(lambdaAggregation),
            trendline: trendlineOf(lambdaAggregation),
        };
        serviceCostArray.push(temp)

        temp = {
            id: "Cloudwatch",
            aggregation: cloudwatchAggregation,
            change: changeOf(cloudwatchAggregation),
            trendline: trendlineOf(cloudwatchAggregation),
        };
        serviceCostArray.push(temp)

        temp = {
            id: "EFS",
            aggregation: efsAggregation,
            change: changeOf(efsAggregation),
            trendline: trendlineOf(efsAggregation),
        };
        serviceCostArray.push(temp)

        temp = {
            id: "ECR",
            aggregation: ecrAggregation,
            change: changeOf(ecrAggregation),
            trendline: trendlineOf(ecrAggregation),
        };
        serviceCostArray.push(temp)

        temp = {
            id: "KMS",
            aggregation: kmsAggregation,
            change: changeOf(kmsAggregation),
            trendline: trendlineOf(kmsAggregation),
        };
        serviceCostArray.push(temp)

        temp = {
            id: "Other",
            aggregation: otherAggregation,
            change: changeOf(otherAggregation),
            trendline: trendlineOf(otherAggregation),
        };
        serviceCostArray.push(temp)

        const groupedCosts: Record<string, Cost[]> = {}
        groupedCosts["AWS Services"] = serviceCostArray

        cost.groupedCosts = groupedCosts

        return cost;
    }

    async getProductInsights(_: ProductInsightsOptions): Promise<Entity> {
        return {
            id: 'remove-me',
            aggregation: [0, 0],
            change: {
                ratio: 0,
                amount: 0
            },
            entities: {}
        }
    }
}

export function trendlineOf(aggregation: DateAggregation[]): Trendline {
    const data: ReadonlyArray<DataPoint> = aggregation.map(a => [
        Date.parse(a.date) / 1000,
        a.amount,
    ]);
    const result = regression.linear(data, { precision: 5 });
    return {
        slope: result.equation[0],
        intercept: result.equation[1],
    };
}
export function changeOf(aggregation: DateAggregation[]): ChangeStatistic {
    const firstAmount = aggregation.length ? aggregation[0].amount : 0;
    const lastAmount = aggregation.length
        ? aggregation[aggregation.length - 1].amount
        : 0;

    // if either the first or last amounts are zero, the rate of increase/decrease is infinite
    if (!firstAmount || !lastAmount) {
        return {
            amount: lastAmount - firstAmount,
        };
    }

    return {
        ratio: (lastAmount - firstAmount) / firstAmount,
        amount: lastAmount - firstAmount,
    };
}

export function aggregationFor(
    intervals: string,
    baseline: number,
): DateAggregation[] {
    const { duration, endDate } = parseIntervals(intervals);
    const inclusiveEndDate = inclusiveEndDateOf(duration, endDate);
    const days = DateTime.fromISO(endDate).diff(
        DateTime.fromISO(inclusiveStartDateOf(duration, inclusiveEndDate)),
        'days',
    ).days;

    function nextDelta(): number {
        const varianceFromBaseline = 0.15;
        // Let's give positive vibes in trendlines - higher change for positive delta with >0.5 value
        const positiveTrendChance = 0.55;
        const normalization = positiveTrendChance - 1;
        return baseline * (Math.random() + normalization) * varianceFromBaseline;
    }

    return [...Array(days).keys()].reduce(
        (values: DateAggregation[], i: number): DateAggregation[] => {
            const last = values.length ? values[values.length - 1].amount : baseline;
            const date = DateTime.fromISO(
                inclusiveStartDateOf(duration, inclusiveEndDate),
            )
                .plus({ days: i })
                .toFormat(DEFAULT_DATE_FORMAT);
            const amount = Math.max(0, last + nextDelta());
            values.push({
                date: date,
                amount: amount,
            });
            return values;
        },
        [],
    );
}

export function exclusiveEndDateOf(
    duration: Duration,
    inclusiveEndDate: string,
): string {
    switch (duration) {
        case Duration.P7D:
        case Duration.P30D:
        case Duration.P90D:
        case Duration.P180D:
            return DateTime.fromISO(inclusiveEndDate)
                .plus({ days: 1 })
                .toFormat(DEFAULT_DATE_FORMAT);
        default:
            return ""
    }
}

export function inclusiveEndDateOf(
    duration: Duration,
    inclusiveEndDate: string,
): string {
    return DateTime.fromISO(exclusiveEndDateOf(duration, inclusiveEndDate))
        .minus({ days: 1 })
        .toFormat(DEFAULT_DATE_FORMAT);
}

export function quarterEndDate(inclusiveEndDate: string): string {
    const endDate = DateTime.fromISO(inclusiveEndDate);
    const endOfQuarter = endDate.endOf('quarter').toFormat(DEFAULT_DATE_FORMAT);
    if (endOfQuarter === inclusiveEndDate) {
        return endDate.toFormat(DEFAULT_DATE_FORMAT);
    }
    return endDate
        .startOf('quarter')
        .minus({ days: 1 })
        .toFormat(DEFAULT_DATE_FORMAT);
}
export function inclusiveStartDateOf(
    duration: Duration,
    inclusiveEndDate: string,
): string {
    switch (duration) {
        case Duration.P7D:
        case Duration.P30D:
        case Duration.P90D:
        case Duration.P180D:
            return DateTime.fromISO(inclusiveEndDate)
                .minus(
                    LuxonDuration.fromISO(duration).plus(LuxonDuration.fromISO(duration)),
                )
                .toFormat(DEFAULT_DATE_FORMAT);
        default:
            return "";
    }
}
type IntervalFields = {
    duration: Duration;
    endDate: string;
};

function parseIntervals(intervals: string): IntervalFields {
    const match = intervals.match(
        /\/(?<duration>P\d+[DM])\/(?<date>\d{4}-\d{2}-\d{2})/,
    );
    if (Object.keys(match?.groups || {}).length !== 2) {
        throw new Error(`Invalid intervals: ${intervals}`);
    }
    const { duration, date } = match!.groups!;
    return {
        duration: duration as Duration,
        endDate: date,
    };
}